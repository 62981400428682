/* eslint-disable max-len */
/* eslint-disable react/no-children-prop */
/* eslint-disable max-lines-per-function */
import '../styles/Home.scss'
import 'swiper/components/pagination'
import 'swiper/swiper.scss'
import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeContacts from '../components/HomeContacts'
import React, { useEffect, useState } from 'react'
import SEO, { getSeoMedia } from '../atoms/SEO'
import SwiperCore, { Pagination } from 'swiper'
import { AboutPsychologists } from '../molecules/Main/AboutPsychologists'
import { AgeLimitWarningPopup } from '../organisms/AgeLimitWarningPopup'
import { Banner } from '../molecules/Main/Banner'
import { BannerWithButton } from '../molecules/Main/BannerWithButton'
import { Button } from '../atoms/Button'
import { CommentsBlock } from '../molecules/Main/CommentsBlock'
import { HowTo } from '../molecules/Main/HowTo'
import { InfoBlock } from '../molecules/Main/InfoBlock'
import { JsonLd, LogoJsonLd } from 'gatsby-plugin-next-seo'
import { OurAwardsNew } from '../molecules/Main/OurAwardsNew'
import { PartnersBlock } from '../molecules/Main/Partners'
import { PsychologistsBlock } from '../molecules/Main/PsychologistsBlock'
import { QuestionListMain } from '../molecules/QuestionListMain'
import { SessionsInfo } from '../molecules/Main/SessionsInfo'
import { TherapyFormats } from '../molecules/Main/TherapyFormats'
import { ThreeWhales } from '../molecules/Main/ThreeWhales'
import { WorkingAreas } from '../molecules/Main/WorkingAreas'
import { getLeads } from '../api'
import { graphql, navigate } from 'gatsby'
import { loadPartnersLogo } from '../state/reducers/partnersSlice'
import { pick } from 'rambda'
import { useDispatch } from 'react-redux'
import { useNewOpenForm } from '../components/GA'

SwiperCore.use([Pagination])

const advantages = [
  {
    title: '7 лет',
    description: 'средний опыт работы специалистов'
  },
  {
    title: '95%',
    description: 'клиентов находят своего психолога с первого раза'
  },
  {
    title: '5 лет',
    description: 'помогаем людям найти своего терапевта'
  }
]

const howToJsonLdSteps = [
  {
    title: 'Заполните анкету',
    description:
      'Расскажите, с чем нужна помощь, и оставьте контактные данные. Если не получается сформулировать запрос, оставьте поле пустым — дальше разберёмся вместе'
  },
  {
    title: 'Мы вам напишем',
    description:
      'В течение 24 часов наш специалист по подбору психолога напишет в WhatsApp или SMS, уточнит запрос и предложит тариф'
  },
  {
    title: 'Выберите психолога',
    description:
      'Мы пришлём ссылку для оплаты сеанса, проанализируем ваш запрос и вместе подберём психолога'
  },
  {
    title: 'Ваш сеанс начинается',
    description:
      'Психолог напишет, чтобы договориться о начале работы. Бывает, что специалист не подошел, — это нормально. Мы поможем подобрать другого'
  }
]
const isDocument = () => typeof document !== 'undefined'

const IndexPage = ({ data, location }) => {
  const [isAgeLimitWarning, setAgeLimitWarning] = useState(
    Boolean(location?.state?.showAgeLimitWarning)
  )
  let root

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    root = document.querySelector('.root')
  }, [])

  useEffect(() => {
    if (isDocument()) {
      root.scrollTo(0, 0)
      root.classList.remove('root_fixed')
    }
  }, [root])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadPartnersLogo())
  }, [dispatch])

  const [_, setLeadsTotal] = useState()

  useEffect(() => {
    if (!process.env.GATSBY_PROD) {
      return
    }
    const getLeadsTotal = async () => {
      try {
        const bitrixResponse = await getLeads()
        setLeadsTotal(bitrixResponse.leadsTotal)
      } catch (error) {
        // this error will be replaced with slackbot alarm
        // eslint-disable-next-line no-console
        console.error(`Couldn't get number of leads; error is ${error}`)
      }
    }
    getLeadsTotal()
  }, [])

  return (
    <App>
      <SEO
        children={
          <>
            {isAgeLimitWarning && (
              <AgeLimitWarningPopup
                hide={() => {
                  setAgeLimitWarning(false)
                  return navigate(`/`, {
                    state: {
                      showAgeLimitWarning: false
                    }
                  })
                }}
              />
            )}
            <LogoJsonLd
              logo={`${process.env.GATSBY_SITEURL}/icons/icon-384x384.png`}
              overrides={{
                '@context': 'http://www.schema.org',
                '@type': 'Organization',
                url: String(process.env.GATSBY_SITEURL),
                logo: `${process.env.GATSBY_SITEURL}/icons/icon-512x512.png?v=0e996bcaaea76d15b8c853ca0e115fd6`,
                name: 'YouTalk',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress:
                    'Юридический адрес: 105005, Москва, Вн.тер.г.муниципальный округ Басманный, ул 2-я Бауманская д. 9/23, стр. 3, ком. 19',
                  addressLocality: 'Москва',
                  postalCode: '105005',
                  addressCountry: 'Россия'
                }
              }}
              url={String(process.env.GATSBY_SITEURL)}
            />
            <JsonLd
              json={{
                '@context': 'https://schema.org',
                '@type': 'HowTo',
                image: {
                  '@type': 'ImageObject',
                  url: `${process.env.GATSBY_SITEURL}/img/seo/index/frame-how-to.png`,
                  height: '406',
                  width: '305'
                },
                name: 'Как всё будет',
                step: howToJsonLdSteps.map(({ title, description }, index) => ({
                  '@type': 'HowToSection',
                  name: title,
                  position: String(index + 1),
                  itemListElement: [
                    {
                      '@type': 'HowToStep',
                      position: String(index + 1),
                      name: title,
                      image: `${process.env.GATSBY_SITEURL}/img/seo/index/${
                        index + 1
                      }-step.png`,
                      url: `${process.env.GATSBY_SITEURL}/form/`,
                      itemListElement: [
                        {
                          '@type': 'HowToDirection',
                          position: String(index + 1),
                          text: description
                        }
                      ]
                    }
                  ]
                }))
              }}
            />
          </>
        }
        description="Психологическая помощь онлайн ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
        images={getSeoMedia([{ pathname: '/img/og/home.png', alt: 'home' }])}
        title="Подбор и консультация психолога онлайн — сервис по поиску психологической помощи YouTalk"
      />
      <Header />
      <main className="main">
        <section className="home-top">
          <div className="container">
            <div className="row">
              <div className="designer-col col-lg-11">
                <div className="home-top__middle-block middle-block">
                  <div className="middle-block__content">
                    <h1 className="middle-block__title">
                      Онлайн консультации с&nbsp;психологом
                    </h1>
                    <p className="middle-block__desc">
                      Заполните анкету — мы подберем специалистов, которые умеют
                      работать с&nbsp;вашим запросом
                    </p>
                    <div className="middle-block__button-block">
                      <Button.NewPrimary
                        href="/wizard/"
                        onClick={useNewOpenForm('mainPageFirstScreen')}
                        size="large"
                        type="link"
                        width="fullScreen"
                      >
                        Подобрать психолога
                      </Button.NewPrimary>
                      <p className="middle-block__button-desc">
                        от 2 000 ₽ за&nbsp;сессию 50 мин
                      </p>
                    </div>
                  </div>
                  <div className="middle-block__wrap-img">
                    <img
                      alt="Девушка на первом плане сидит в кресле с ноутбуком. Справа мужчина смотрит в телефон. Сзади девушка у столика слушает музыку."
                      src="../img/icons/home-decor/home-decor-new.svg"
                    />
                  </div>
                </div>
                <div className="middle-block__advantages">
                  {advantages.map((advantage, i) => (
                    <div
                      key={`advantage${i}`}
                      className="middle-block__advantages__item"
                    >
                      <p className="middle-block__advantages__title">
                        {advantage.title}
                      </p>
                      <p className="middle-block__advantages__desc middle-block__max">
                        {advantage.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <WorkingAreas />
        <ThreeWhales />
        <InfoBlock />
        <AboutPsychologists />
        <HowTo />
        <SessionsInfo />
        <Banner />
        <CommentsBlock />
        <PsychologistsBlock data={pick(['psychologists'], data)} />
        <TherapyFormats />
        <BannerWithButton />
        <PartnersBlock />
        <OurAwardsNew />
        <QuestionListMain />
        <HomeContacts />
      </main>
      <Footer />
    </App>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    psychologists: allPsychologistsData(
      filter: { is_active: { in: true }, nearest_slot: { ne: null } }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          user {
            id
          }
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          nearest_slot
          self_appointment
          sync_messages
          start_of_practice
          video
          video_url
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
          rates {
            startDate
            finishDate
            prices {
              price
              language
              type
            }
          }
        }
      }
      totalCount
    }
  }
`
